* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p,
h5,
h6 {
  margin: 0;
  font-weight: 400;
}

h2,
h3,
h4 {
  margin: 0;
  font-weight: 500;
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 80%;
  }
}

.onfido-sdk-ui-Theme-footer {
  display: none !important;
  opacity: 0;
}

.onfido-sdk-ui-Theme-step {
  padding-bottom: 20px;
}

.onfido-sdk-ui-Error-container-warning,
.onfido-sdk-ui-CameraError-errorMessage {
  display: none;
}

.onfido-sdk-ui-Modal-inner {
  /* background: transparent !important; */
  /* background-color: transparent !important; */
}

/* #intercom-container .intercom-launcher-frame,
#intercom-positioner-tree .intercom-launcher-frame {
  bottom: 33px !important;
  right: 33px !important;
} */
